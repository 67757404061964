import { Layout } from "components";
import { graphql } from "gatsby";
import Container from "@material-ui/core/Container";
import React, { useMemo } from "react";
import Helmet from "react-helmet";
import useFilterLocale from "hooks/useFilterLocale";
import Offer from "../components/Offer";

function OfferGroup(props) {
  const {
    pageContext: { locale, node_locale },
    data: { contentfulOfferGroup }
  } = props;

  // const offersList = props.data.contentfulOfferGroup.offers;
  // console.log(offersList)

  // const offers = useMemo(
  //   () =>
  //   offersList.edges.map(edge => ({
  //       ...edge.node,
  //       title: edge.node.serviceName
  //     })),
  //   [offersList.edges]
  // )
  // const filteredOffers = useFilterLocale(offers)
  // console.log(filteredOffers)

  return (
    <Container maxWidth="xl">
      {/* <Helmet
          description={contentfulPage.description}
          htmlAttributes={{ lang: node_locale || locale }}
          title={contentfulPage.metaTitle}
        >
          <meta name='description' content={contentfulPage.metaDescription}></meta>
          <meta name='title' content={contentfulPage.metaTitle}></meta>
          </Helmet> */}
      {props.data.contentfulOfferGroup.offers.map(offer => (
        <Offer offer={offer} />
      ))}
    </Container>
  );
}

export default OfferGroup;

export const offerQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulOfferGroup(slug: { eq: $slug }) {
      node_locale
      offers {
        ... on ContentfulOffer {
          title
        }
        buttonLabel
        buttonLink {
          ... on ContentfulPage {
            slug
          }
        }
        coverPhoto {
          fluid(maxWidth: 800, quality: 90) {
            src
          }
        }
        mainHeading
        id
        node_locale

        shortDescription
        slug
        title
        tourPrice
        tourDetails {
          json
        }
        description {
          json
        }
      }
      slug
      title
      shortDescription
      node_locale
      id
    }
  }
`;
